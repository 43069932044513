const config = {
    axios: {
        baseURI: 'https://api.startapp.org.il:9000',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
}

export default config